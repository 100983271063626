import { ref, computed } from 'vue'
import type {
  FilterField,
  ColumnType,
  SingleFilterField,
  CompositeFilterField,
  FilterType
} from '../types'

type CustomFilter = {
  [index: string]: FilterField
}

const filterLabels: Record<string, string> = {
  contains: 'Contains',
  equals: 'Equals',
  notContains: 'Not Contains',
  notEquals: 'Not Equals',
  startsWith: 'Starts With',
  endsWith: 'Ends With',
  empty: 'Empty',
  notEmpty: 'Not Empty',
  greaterThan: 'Greater Than',
  greaterThanOrEquals: 'Greater Than Or Equals',
  lessThan: 'Less Than',
  lessThanOrEquals: 'Less Than Or Equals',
  between: 'Between',
  before: 'Before',
  after: 'After'
}

const activeFilters = ref<Record<string, FilterField>>({})
const pendingFilters = ref<Record<string, FilterField>>({})
const originalFilters = ref<Record<string, FilterField>>({})

function initializeFilters(filters: Record<string, FilterField>) {
  originalFilters.value = JSON.parse(JSON.stringify(filters))
  activeFilters.value = { ...filters }
  pendingFilters.value = { ...filters }
}

function createInitialFilter(column: ColumnType): FilterField {
  return {
    filter: column.type === 'select' ? [] : '',
    filterType: column.type,
    type: column.type === 'select' ? 'in' : column.type === 'number' ? 'equals' : 'contains',
    isSelectMultipleOptions: column.isSelectMultipleOptions,
    isStringify: column.stringify
  }
}

function updateFilterValue(column: ColumnType, value: string) {
  if (!pendingFilters.value[column.name]) {
    pendingFilters.value[column.name] = createInitialFilter(column)
  }

  let currentFilter = pendingFilters.value[column.name]

  if (isSingleFilterField(currentFilter)) {
    handleSingleFilterField(currentFilter, column, value)
  } else if (isCompositeFilterField(currentFilter)) {
    handleCompositeFilterField(currentFilter, column, value)
  }

  if (isFilterEmpty(pendingFilters.value[column.name])) {
    delete pendingFilters.value[column.name]
  }
}

function handleSingleFilterField(
  currentFilter: SingleFilterField,
  columnType: ColumnType,
  value: string
) {
  currentFilter.isSelectMultipleOptions = columnType.isSelectMultipleOptions
  currentFilter.isStringify = columnType.stringify
  currentFilter.filterType = columnType.type

  if (columnType.type === 'select') {
    currentFilter.filter = updateSelectedOptions(
      currentFilter.filter as string[],
      value
    )
  } else {
    currentFilter.filter = value
  }
}

function handleCompositeFilterField(
  currentFilter: CompositeFilterField,
  columnType: ColumnType,
  value: string
) {
  if (currentFilter.condition2) {
    if (columnType.type === 'select') {
      currentFilter.condition2.filter = updateSelectedOptions(
        currentFilter.condition2.filter as string[],
        value
      )
      if (currentFilter.condition2.filter === undefined) {
      }
    } else {
      if (currentFilter.condition2) {
        currentFilter.condition2.filter = value
      }
    }

    if (isFilterEmpty(currentFilter.condition2)) {
      if (currentFilter.condition1) {
        pendingFilters.value[columnType.name] = {
          ...currentFilter,
          condition2: {
            filterType: 'select',
            type: 'in',
            filter: [],
            isSelectMultipleOptions: columnType.isSelectMultipleOptions,
            isStringify: columnType.stringify
          }
        }
      } else {
        delete pendingFilters.value[columnType.name]
      }
    }
  }
}

function updateSelectedOptions(
  selectedOptions: string[],
  value: string
): string[] {
  return selectedOptions.includes(value)
    ? selectedOptions.filter(v => v !== value)
    : [...selectedOptions, value]
}

function applyCustomFilter(filterField: Record<string, FilterField>) {
  const filterName = Object.keys(filterField)[0]
  pendingFilters.value[filterName] = filterField[filterName]
}

function removeCustomFilter(column: string) {
  if (pendingFilters.value[column]) {
    delete pendingFilters.value[column]
  }
}

function handleEmptyFilter(
  column: string,
  isEmpty: boolean,
  type: FilterType,
  stringify: boolean,
  isSelectMultipleOptions: boolean
) {
  const condition: SingleFilterField = {
    filterType: type,
    type: isEmpty ? 'empty' : 'notEmpty',
    filter: '',
    isStringify: stringify,
    isSelectMultipleOptions: isSelectMultipleOptions
  }

  if (
    pendingFilters.value[column] &&
    isCompositeFilterField(pendingFilters.value[column])
  ) {
    if (
      pendingFilters.value[column].condition1.filter === condition.filter &&
      pendingFilters.value[column].condition1.filterType ===
        condition.filterType &&
      pendingFilters.value[column].condition1.type === condition.type
    ) {
      if (isFilterEmpty(pendingFilters.value[column].condition2)) {
        delete pendingFilters.value[column]
      } else {
        pendingFilters.value[column] = {
          ...pendingFilters.value[column].condition2
        }
      }
      return
    }
  }

  if (
    !pendingFilters.value[column] ||
    isSingleFilterField(pendingFilters.value[column])
  ) {
    pendingFilters.value[column] = {
      operator: 'OR',
      condition1: condition,
      condition2: {
        filterType: type,
        type: 'in',
        filter: pendingFilters.value[column]?.filter || [],
        isStringify: stringify,
        isSelectMultipleOptions: isSelectMultipleOptions
      },
      filterType: type,
      isStringify: stringify,
      isSelectMultipleOptions: isSelectMultipleOptions
    }
  } else if (isCompositeFilterField(pendingFilters.value[column])) {
    if (
      !pendingFilters.value[column].condition1.filter ||
      pendingFilters.value[column].condition1.filter === ''
    ) {
      pendingFilters.value[column].condition1 = condition
    } else {
      pendingFilters.value[column].condition2 = condition
    }
  }
}

function applyFilters() {
  activeFilters.value = JSON.parse(JSON.stringify(pendingFilters.value))
}

function cancelFilters() {
  pendingFilters.value = JSON.parse(JSON.stringify(activeFilters.value))
}

function resetFilters() {
  activeFilters.value = JSON.parse(JSON.stringify(originalFilters.value))
  pendingFilters.value = JSON.parse(JSON.stringify(originalFilters.value))
}

// Utility functions
function isSingleFilterField(filter: FilterField): filter is SingleFilterField {
  return 'filter' in filter && 'type' in filter
}

function isCompositeFilterField(
  filter: FilterField
): filter is CompositeFilterField {
  return (
    'condition1' in filter && 'condition2' in filter && 'operator' in filter
  )
}

function isFilterEmpty(filter: FilterField): boolean {
  if (isSingleFilterField(filter)) {
    return (
      (Array.isArray(filter.filter) && filter.filter.length === 0) ||
      filter.filter === ''
    )
  } else if (isCompositeFilterField(filter)) {
    return !filter.condition1 && !filter.condition2
  }
  return true
}

function updateCustomFilter(filterField: Record<string, FilterField>) {
  const filterName = Object.keys(filterField)[0]
  pendingFilters.value[filterName] = filterField[filterName]
}

function getFilterLabel(type: string): string {
  return filterLabels[type] || type
}

function getFilterText(filter: CustomFilter, columnName: string): string {
  const filterValue = filter[columnName]
  if (!filterValue) {
    return ''
  }

  if (filterStore.isCompositeFilterField(filterValue)) {
    return `${getFilterLabel(filterValue.condition1.type)} ${
      filterValue.condition1.filter
    } ${filterValue.operator} ${getFilterLabel(filterValue.condition2.type)} ${
      filterValue.condition2.filter
    }`
  }

  return `${getFilterLabel(filterValue.type)} ${filterValue.filter}`
}

const isBetweenCondition = (type: string, value: any) => {
  return (
    type === 'between' && value && Array.isArray(value) && value.length === 2
  )
}

const isEmptyOrNotEmptyCondition = (type: string) => {
  return type === 'empty' || type === 'notEmpty'
}

function removeFilter(column: string) {
  delete pendingFilters.value[column]
  applyFilters()
}

function hasFilter(column: string) {
  return !!activeFilters.value[column]
}

const activeFiltersCount = computed(() => {
  console.log(activeFilters.value);
  return Object.values(activeFilters.value).length
  return Object.values(activeFilters.value).filter(
    filter => !isFilterEmpty(filter)
  ).length
})

const removeFilters = (column: string) => {
  delete pendingFilters.value[column]
}

export const filterStore = {
  activeFilters: computed(() => activeFilters.value),
  pendingFilters: computed(() => pendingFilters.value),
  activeFiltersCount,
  initializeFilters,
  updateFilterValue,
  applyCustomFilter,
  removeCustomFilter,
  handleEmptyFilter,
  applyFilters,
  cancelFilters,
  resetFilters,
  updateCustomFilter,
  isSingleFilterField,
  isCompositeFilterField,
  getFilterText,
  isBetweenCondition,
  isEmptyOrNotEmptyCondition,
  removeFilter,
  removeFilters,
  hasFilter
}
