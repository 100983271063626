<script lang="ts" setup>
  import { defineProps, ref, computed, watch } from 'vue'
  import Input from '../../../ui/input/Input.vue'
  import Button from '../../../ui/button/Button.vue'
  import {
    ElSelect,
    ElOption,
    ElForm,
    ElFormItem,
    ElDatePicker,
    ElRadioGroup,
    ElRadio,
    ElTooltip
  } from 'element-plus'

  import {
    FilterField,
    SingleFilterField,
    FilterConditionType,
    ColumnType,
    ColsDefs,
    FilterType
  } from '../types'
  import { cn } from '../../../../../lib/utils'

  import moment from 'moment'

  interface Params {
    column: ColsDefs
    isGridFilter: boolean
    api: any
  }

  const props = defineProps<{
    params: Params
  }>()

  import { filterStore } from '.././utils/filterStore'

  const {
    pendingFilters,
    isCompositeFilterField,
    isSingleFilterField,
    updateFilterValue,
    removeCustomFilter,
    updateCustomFilter,
    applyFilters,
    cancelFilters,
    handleEmptyFilter,

    getFilterText,
    isBetweenCondition,
    isEmptyOrNotEmptyCondition
  } = filterStore

  const column = computed(() => props.params.column)

  type CustomFilter = {
    [index: string]: FilterField
  }

  // Consts
  const shortcuts = [
    {
      text: 'Last week',
      value: () => {
        const end = new Date()
        const start = new Date()
        start.setTime(start.getTime() - 3600 * 1000 * 24 * 7)
        return [start, end]
      }
    },
    {
      text: 'This week',
      value: () => {
        const start = moment().startOf('week').toDate()
        const end = moment().endOf('week').toDate()
        return [start, end]
      }
    },
    {
      text: 'Last month',
      value: () => {
        const start = moment().subtract(1, 'month').startOf('month').toDate()
        const end = moment().subtract(1, 'month').endOf('month').toDate()
        return [start, end]
      }
    },
    {
      text: 'This month',
      value: () => {
        const start = moment().startOf('month').toDate()
        const end = moment().endOf('month').toDate()
        return [start, end]
      }
    },
    {
      text: 'This year',
      value: () => {
        const start = moment().startOf('year').toDate()
        const end = moment().endOf('year').toDate()
        return [start, end]
      }
    },
    {
      text: 'Last year',
      value: () => {
        const start = new Date(new Date().getFullYear() - 1, 0, 1)
        const end = new Date(new Date().getFullYear() - 1, 11, 31)
        return [start, end]
      }
    },
    {
      text: `Q1 ${new Date().getFullYear()}`,
      value: () => {
        const start = new Date(new Date().getFullYear(), 0, 1)
        const end = new Date(new Date().getFullYear(), 2, 31)
        return [start, end]
      }
    },
    {
      text: `Q2 ${new Date().getFullYear()}`,
      value: () => {
        const start = new Date(new Date().getFullYear(), 3, 1)
        const end = new Date(new Date().getFullYear(), 5, 30)
        return [start, end]
      }
    },
    {
      text: `Q3 ${new Date().getFullYear()}`,
      value: () => {
        const start = new Date(new Date().getFullYear(), 6, 1)
        const end = new Date(new Date().getFullYear(), 8, 30)
        return [start, end]
      }
    },
    {
      text: `Q4 ${new Date().getFullYear()}`,
      value: () => {
        const start = new Date(new Date().getFullYear(), 9, 1)
        const end = new Date(new Date().getFullYear(), 11, 31)
        return [start, end]
      }
    }
  ]

  // Refs
  const filterColumns = ref('')
  const isShowFilterForm = ref(true)
  const customFilterTypeOption1 = ref<FilterConditionType | null>(null)
  const selectFilterValue1 = ref<string | string[] | null>(null)
  const customFilterTypeOption2 = ref<FilterConditionType | null>(null)
  const selectFilterValue2 = ref<string | null>(null)
  const selectFilterValue1Start = ref<string | null>(null)
  const selectFilterValue1End = ref<string | null>(null)
  const logicOperator = ref<'and' | 'or'>('and')
  const customFilter = ref<CustomFilter | null>(null)

  const activeFilters = computed(() => {
    const columnType = column.value.type
    if (columnType !== 'select') {
      if (pendingFilters.value[column.value.name]) {
        initializeFilter(pendingFilters.value[column.value.name] as FilterField)
      }
    }

    return pendingFilters.value
  })

  function initializeFilter(filter: FilterField) {
    if (isCompositeFilterField(filter)) {
      customFilterTypeOption1.value = filter.condition1.type
      selectFilterValue1.value = filter.condition1.filter || ''
      customFilterTypeOption2.value = filter.condition2.type
      selectFilterValue2.value = filter.condition2.filter as string
      logicOperator.value = filter.operator.toLowerCase() as 'and' | 'or'
    } else if (isSingleFilterField(filter)) {
      customFilterTypeOption1.value = filter.type

      if (filter.type === 'between' && Array.isArray(filter.filter)) {
        selectFilterValue1.value = filter.filter
        selectFilterValue1Start.value = filter.filter[0]
        selectFilterValue1End.value = filter.filter[1]
      } else {
        selectFilterValue1.value = filter.filter as string
      }

      if (filter.type === 'empty' || filter.type === 'notEmpty') {
        selectFilterValue1.value = ''
      }

      if (filter.type === 'empty' || filter.type === 'notEmpty') {
        selectFilterValue1.value = ''
      }
    }

    customFilter.value = {
      [column.value.name]: filter
    }
  }

  const filteredOptions = computed(() => {
    const regex = new RegExp(filterColumns.value, 'i')
    return column.value.options?.filter(option => regex.test(option.name))
  })

  const inputType = computed((): string => {
    switch (column.value.type) {
      case 'text':
        return 'text'
      case 'number':
        return 'number'
      case 'date':
        return 'date'
      case 'boolean':
        return 'boolean'
      case 'time':
        return 'time'
      default:
        return 'text'
    }
  })

  const customFilterOptions = computed(() => {
    switch (column.value.type) {
      case 'text':
        return [
          { id: 'contains', label: 'Contains', value: 'contains' },
          { id: 'equals', label: 'Equals', value: 'equals' },
          {
            id: 'notContains',
            label: 'Not Contains',
            value: 'notContains'
          },
          {
            id: 'notEquals',
            label: 'Not Equals',
            value: 'notEquals'
          },
          {
            id: 'startsWith',
            label: 'Starts With',
            value: 'startsWith'
          },
          { id: 'endsWith', label: 'Ends With', value: 'endsWith' },
          { id: 'empty', label: 'Empty', value: 'empty' },
          { id: 'notEmpty', label: 'Not Empty', value: 'notEmpty' }
        ]
      case 'number':
        return [
          { id: 'equals', label: 'Equals', value: 'equals' },
          {
            id: 'notEquals',
            label: 'Not Equals',
            value: 'notEquals'
          },
          {
            id: 'greaterThan',
            label: 'Greater Than',
            value: 'greaterThan'
          },
          {
            id: 'greaterThanOrEquals',
            label: 'Greater Than Or Equals',
            value: 'greaterThanOrEquals'
          },
          { id: 'lessThan', label: 'Less Than', value: 'lessThan' },
          {
            id: 'lessThanOrEquals',
            label: 'Less Than Or Equals',
            value: 'lessThanOrEquals'
          },
          { id: 'between', label: 'Between', value: 'between' },
          { id: 'empty', label: 'Empty', value: 'empty' },
          { id: 'notEmpty', label: 'Not Empty', value: 'notEmpty' }
        ]
      case 'date':
        return [
          { id: 'equals', label: 'Equals', value: 'equals' },
          {
            id: 'notEquals',
            label: 'Not Equals',
            value: 'notEquals'
          },
          { id: 'before', label: 'Before', value: 'before' },
          { id: 'after', label: 'After', value: 'after' },
          { id: 'between', label: 'Between', value: 'between' },
          { id: 'empty', label: 'Empty', value: 'empty' },
          { id: 'notEmpty', label: 'Not Empty', value: 'notEmpty' }
        ]
      case 'boolean':
        return [
          { id: 'equals', label: 'Equals', value: 'equals' },
          { id: 'notEquals', label: 'Not Equals', value: 'notEquals' }
        ]
      case 'time':
        return [
          { id: 'equals', label: 'Equals', value: 'equals' },
          {
            id: 'notEquals',
            label: 'Not Equals',
            value: 'notEquals'
          },
          {
            id: 'greaterThan',
            label: 'Greater Than',
            value: 'greaterThan'
          },
          {
            id: 'greaterThanOrEquals',
            label: 'Greater Than Or Equals',
            value: 'greaterThanOrEquals'
          },
          { id: 'lessThan', label: 'Less Than', value: 'lessThan' },
          {
            id: 'lessThanOrEquals',
            label: 'Less Than Or Equals',
            value: 'lessThanOrEquals'
          },
          { id: 'between', label: 'Between', value: 'between' },
          { id: 'empty', label: 'Empty', value: 'empty' },
          { id: 'notEmpty', label: 'Not Empty', value: 'notEmpty' }
        ]
      default:
        return []
    }
  })

  function createNewFilter() {
    isShowFilterForm.value = true
  }

  function removeFilter() {
    customFilter.value = {
      [column.value.name]: {
        filter: '',
        filterType: 'text',
        type: 'contains'
      }
    }

    removeCustomFilter(column.value.name)
    resetFilterForm()
  }

  function resetFilterForm() {
    customFilter.value = null
    selectFilterValue1.value = null
    selectFilterValue1Start.value = null
    selectFilterValue1End.value = null
    customFilterTypeOption2.value = null
    selectFilterValue2.value = null
  }

  function saveFilter() {
    if (!customFilterTypeOption1.value) {
      return
    }

    const filterType = column.value.type as FilterType
    const isSelectMultipleOptions = column.value.isSelectMultipleOptions
    const isStringify = column.value.stringify

    if (
      isBetweenCondition(
        customFilterTypeOption1.value,
        selectFilterValue1.value
      )
    ) {
      customFilter.value = {
        [column.value.name]: {
          filterType,
          type: 'between',
          filter: selectFilterValue1.value,
          isSelectMultipleOptions,
          isStringify
        }
      }
    }

    if (
      customFilterTypeOption1.value &&
      (selectFilterValue1.value ||
        isEmptyOrNotEmptyCondition(customFilterTypeOption1.value))
    ) {
      const condition1: SingleFilterField = {
        filterType,
        type: customFilterTypeOption1.value,
        filter:
          customFilterTypeOption1.value === 'empty' ||
          customFilterTypeOption1.value === 'notEmpty'
            ? ''
            : selectFilterValue1.value || '',
          isSelectMultipleOptions,
          isStringify
      }

      if (
        column.value.type === 'date' &&
        customFilterTypeOption2.value &&
        isEmptyOrNotEmptyCondition(customFilterTypeOption2.value)
      ) {
        const condition2: SingleFilterField = {
          filterType,
          type: customFilterTypeOption2.value,
          filter: '',
          isSelectMultipleOptions,
          isStringify
        }

        customFilter.value = {
          [column.value.name]: {
            filterType,
            operator: logicOperator.value.toUpperCase() as 'AND' | 'OR',
            condition1,
            condition2,
            isSelectMultipleOptions,
            isStringify
          }
        }
      } else if (
        customFilterTypeOption2.value &&
        (selectFilterValue2.value ||
          isEmptyOrNotEmptyCondition(customFilterTypeOption2.value))
      ) {
        const condition2: SingleFilterField = {
          filterType,
          type: customFilterTypeOption2.value,
          filter: selectFilterValue2.value,
          isSelectMultipleOptions,
          isStringify
        }

        customFilter.value = {
          [column.value.name]: {
            filterType,
            operator: logicOperator.value.toUpperCase() as 'AND' | 'OR',
            condition1,
            condition2,
            isSelectMultipleOptions,
            isStringify
          }
        }
      } else {
        customFilter.value = {
          [column.value.name]: condition1
        }
      }

      if (customFilter.value) {
        updateCustomFilter(customFilter.value)
      }
      // isShowFilterForm.value = false
    }
  }

  function emptyFilter(
    column1: string,
    value: string,
    stringify: boolean,
    isSelectMultipleOptions: boolean
  ) {
    console.log('stringify', stringify)
    const isEmpty = value === 'empty'
    handleEmptyFilter(
      column1,
      isEmpty,
      column.value.type as FilterType,
      stringify,
      isSelectMultipleOptions
    )
  }

  function editFilter() {
    isShowFilterForm.value = true
  }

  const isSaveDisabled = computed(() => {
    if (
      customFilterTypeOption1.value === 'between' &&
      (!selectFilterValue1.value ||
        !Array.isArray(selectFilterValue1.value) ||
        selectFilterValue1.value.length !== 2)
    ) {
      return true
    }

    if (
      customFilterTypeOption1.value &&
      (selectFilterValue1.value ||
        customFilterTypeOption1.value === 'empty' ||
        customFilterTypeOption1.value === 'notEmpty')
    ) {
      if (selectFilterValue1.value) {
        return false
      }

      if (
        column.value.type === 'date' &&
        (customFilterTypeOption2.value === 'empty' ||
          customFilterTypeOption2.value === 'notEmpty')
      ) {
        return false
      }
      if (customFilterTypeOption2.value && selectFilterValue2.value) {
        return false
      }

      if (
        customFilterTypeOption1.value === 'empty' ||
        customFilterTypeOption1.value === 'notEmpty'
      ) {
        return false
      }
    }

    return true
  })

  function isFilterActive(
    option: { id: string; name: string } | string
  ): boolean {
    const currentFilter = activeFilters.value[column.value.name]
    if (!currentFilter) return false

    if (typeof option === 'string') {
      if (isCompositeFilterField(currentFilter)) {
        return currentFilter.condition1.type === option
      }
      return currentFilter.type === option
    } else {
      if (column.value.type === 'select') {
        if (isCompositeFilterField(currentFilter)) {
          return (currentFilter.condition2.filter as string[]).includes(
            option.id
          )
        } else {
          return (currentFilter.filter as string[])?.includes(option.id)
        }
      }
      if (isSingleFilterField(currentFilter)) {
        return currentFilter.filter === option.id
      } else {
        return currentFilter.condition1.filter === option.id
      }
    }
  }

  function handleApplyFilter() {
    applyFilters()
  }

  const handleCancel = () => {
    props.params.api.hidePopupMenu()
    cancelFilters()
  }

  const resetFilter = () => {
    filterStore.removeFilters(column.value.name)
  }

  watch(customFilterTypeOption1, () => {
    if (
      customFilterTypeOption1.value === 'empty' ||
      customFilterTypeOption1.value === 'notEmpty'
    ) {
      selectFilterValue1.value = ''
    }
  })

  watch([selectFilterValue1Start, selectFilterValue1End], () => {
    if (selectFilterValue1Start.value && selectFilterValue1End.value) {
      selectFilterValue1.value = [
        selectFilterValue1Start.value,
        selectFilterValue1End.value
      ]
    } else {
      selectFilterValue1.value = null
    }
  })

  watch(
    () => customFilterOptions.value,
    newOptions => {
      if (newOptions.length > 0 && !customFilterTypeOption1.value) {
        customFilterTypeOption1.value = newOptions[0]
          .value as FilterConditionType
      }
    },
    { immediate: true }
  )

  watch(
    [
      selectFilterValue1,
      selectFilterValue2,
      logicOperator,
      customFilterTypeOption1,
      customFilterTypeOption2
    ],
    () => {
      if (!isSaveDisabled.value) {
        saveFilter()
      }
      if (
        !selectFilterValue1.value &&
        customFilterTypeOption1.value !== 'empty' &&
        customFilterTypeOption1.value !== 'notEmpty'
      ) {
        console.log('remove filter')
        console.log(customFilter.value)
        removeFilter()
      }
    }
  )

  const filterCount = computed(() => {
    const filter = activeFilters.value[column.value.name]
    if (filter && filter.filterType === 'select') {
      return (
        (filter.filter?.length || 0) +
        (filter.condition1 ? 1 : 0) +
        (filter.condition2?.filter?.length || 0)
      )
    }
    return 0
  })
</script>

<template>
  <div class="bg-white p-2">
    <div
      class="flex items-center w-full whitespace-nowrap font-bold justify-between my-2">
      <div>
        {{ column.label }}
        <span v-if="filterCount > 0" class="ml-2">
          /
          {{ filterCount }}
        </span>
      </div>
      <div v-if="filterCount > 0">
        <el-button class="text-red-500 px-2" size="small" @click="resetFilter">
          Clear
        </el-button>
      </div>
    </div>

    <div
      v-if="column.type === 'select'"
      class="flex items-center w-full space-x-2">
      <Input
        v-model="filterColumns"
        class="border border-gray-300 rounded cursor-pointer px-2 py-1 truncate w-[200px]"
        placeholder="Search"
        type="text" />
    </div>
    <div
      class="flex flex-col space-y-2 max-h-[350px] overflow-y-auto w-[200px]">
      <div
        v-if="column.type === 'select'"
        class="flex items-center w-full space-x-2"></div>
      <div
        class="flex items-center space-x-2 bg-gray-50 rounded cursor-pointer px-2 py-1 truncate w-[200px]"
        @click="createNewFilter"
        v-else-if="
          !isShowFilterForm && !customFilter && column.type !== 'select'
        ">
        Create new filter
        <svg
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
          stroke-width="1.5"
          stroke="currentColor"
          class="w-4 h-4 cursor-pointer mx-2">
          <path
            stroke-linecap="round"
            stroke-linejoin="round"
            d="M12 4.5v15m7.5-7.5h-15" />
        </svg>
      </div>

      <div
        class="flex flex-col space-y-2 overflow-y-auto bg-gray-50 rounded cursor-pointer p-2"
        v-else>
        <div class="flex justify-between items-center w-full space-x-2">
          <span>Custom Filter:</span>
          <span class="cursor-pointer" @click="isShowFilterForm = false">
            Close
          </span>
        </div>

        <ElForm
          label-width="auto"
          class="w-full h-full space-x-0 space-y-0"
          @submit.prevent>
          <ElFormItem>
            <ElSelect
              v-model="customFilterTypeOption1!"
              placeholder="Select"
              class="w-full">
              <ElOption
                v-for="option in customFilterOptions"
                :key="option.value"
                :label="option.label"
                :value="option.value"
                @mousedown.native.stop.prevent />
            </ElSelect>
          </ElFormItem>

          <ElFormItem v-if="column.type === 'date'">
            <template v-if="customFilterTypeOption1 === 'between'">
              <el-date-picker
                v-model="selectFilterValue1"
                type="daterange"
                unlink-panels
                value-format="YYYY-MM-DD"
                format="MMM-DD-YYYY"
                range-separator="To"
                start-placeholder="Start date"
                end-placeholder="End date"
                :shortcuts="shortcuts" />
            </template>
            <template
              v-else-if="
                customFilterTypeOption1 !== 'empty' &&
                customFilterTypeOption1 !== 'notEmpty'
              ">
              <ElDatePicker
                format="YYYY/MM/DD"
                value-format="YYYY-MM-DD"
                v-model="selectFilterValue1"
                type="date"
                placeholder="Select Date"
                style="width: 100%" />
            </template>
          </ElFormItem>

          <ElFormItem v-else-if="column.type === 'boolean'">
            <ElSelect
              v-model="selectFilterValue1"
              placeholder="Select"
              class="w-full">
              <ElOption
                label="True"
                value="true"
                @mousedown.native.stop.prevent />
              <ElOption
                label="False"
                value="false"
                @mousedown.native.stop.prevent />
            </ElSelect>
          </ElFormItem>

          <ElFormItem v-else>
            <Input
              v-if="customFilterTypeOption1 !== 'between'"
              v-model="selectFilterValue1"
              class="border border-gray-300 rounded cursor-pointer px-2 py-1 truncate"
              :class="{
                hidden:
                  customFilterTypeOption1 === 'empty' ||
                  customFilterTypeOption1 === 'notEmpty' ||
                  !customFilterTypeOption1
              }"
              placeholder="Search"
              :type="inputType" />
            <div v-else class="flex items-center space-x-2">
              <Input
                v-model="selectFilterValue1Start"
                class="border border-gray-300 rounded cursor-pointer px-2 py-1 truncate"
                placeholder="Start"
                :type="inputType" />
              <Input
                v-model="selectFilterValue1End"
                class="border border-gray-300 rounded cursor-pointer px-2 py-1 truncate"
                placeholder="End"
                :type="inputType" />
            </div>
          </ElFormItem>

          <ElFormItem
            class="m-0 p-0 w-full flex items-center"
            v-if="
              customFilterTypeOption1 &&
              selectFilterValue1 &&
              column.type !== 'boolean'
            ">
            <ElRadioGroup v-model="logicOperator">
              <ElRadio label="and">And</ElRadio>
              <ElRadio label="or">Or</ElRadio>
            </ElRadioGroup>
          </ElFormItem>

          <div
            v-if="
              logicOperator && selectFilterValue1 && column.type !== 'boolean'
            ">
            <ElFormItem v-if="logicOperator && selectFilterValue1">
              <ElSelect
                v-model="customFilterTypeOption2"
                placeholder="Select"
                class="w-full"
                clearable>
                <template v-if="column.type === 'date'">
                  <ElOption
                    v-for="option in [
                      {
                        id: 'empty',
                        label: 'Empty',
                        value: 'empty'
                      },
                      {
                        id: 'notEmpty',
                        label: 'Not Empty',
                        value: 'notEmpty'
                      }
                    ]"
                    :key="option.id"
                    :label="option.label"
                    :value="option.value"
                    @mousedown.native.stop.prevent />
                </template>
                <template v-else>
                  <ElOption
                    v-for="option in customFilterOptions"
                    :key="option.id"
                    :label="option.label"
                    :value="option.value"
                    @mousedown.native.stop.prevent />
                </template>
              </ElSelect>
            </ElFormItem>

            <ElFormItem v-if="column.type !== 'date'">
              <Input
                v-model="selectFilterValue2"
                class="border border-gray-300 rounded cursor-pointer px-2 py-1 truncate"
                :class="{
                  hidden:
                    customFilterTypeOption2 === 'empty' ||
                    customFilterTypeOption2 === 'notEmpty' ||
                    !customFilterTypeOption2
                }"
                placeholder="Search"
                :type="inputType" />
            </ElFormItem>
          </div>
        </ElForm>
      </div>

      <div
        class="flex items-center w-full space-x-2"
        v-if="column.type === 'select'">
        <ElTooltip
          effect="dark"
          content="Empty"
          placement="top"
          :show-after="1000">
          <div
            class="border border-gray-300 rounded cursor-pointer w-full mx-1 px-2 py-1 truncate"
            :class="
              cn('bg-gray-50', {
                'bg-blue-400': isFilterActive('empty')
              })
            "
            @click="
              emptyFilter(
                column.name,
                'empty',
                column.stringify || false,
                column.isSelectMultipleOptions || false
              )
            ">
            Empty
          </div>
        </ElTooltip>

        <ElTooltip
          effect="dark"
          content="Not Empty"
          placement="top"
          :show-after="1000">
          <div
            class="border border-gray-300 rounded cursor-pointer w-full mx-1 px-2 py-1 truncate"
            :class="
              cn('bg-gray-50', {
                'bg-blue-400': isFilterActive('notEmpty')
              })
            "
            @click="
              emptyFilter(
                column.name,
                'notEmpty',
                column.stringify || false,
                column.isSelectMultipleOptions || false
              )
            ">
            Not Empty
          </div>
        </ElTooltip>
      </div>
      <div
        v-if="
          column.options?.length && !customFilter && column.type === 'select'
        "
        v-for="option in filteredOptions"
        :key="option.id"
        class="flex items-center w-full space-x-2">
        <ElTooltip
          effect="dark"
          :content="option.name"
          placement="top"
          :show-after="1000">
          <div
            class="border border-gray-300 w-full mx-1 rounded cursor-pointer px-2 py-1 truncate"
            :class="
              cn('bg-gray-50', {
                'bg-blue-400': isFilterActive(option)
              })
            "
            @click="updateFilterValue(column as ColumnType, option.id)">
            {{ option.name }}
          </div>
        </ElTooltip>
      </div>
    </div>

    <div
      v-if="params.isGridFilter"
      class="flex items-center m-2 space-x-2 pr-2 w-1/2 mt-4">
      <Button
        class="w-full bg-gray-500 text-white hover:bg-gray-600"
        variant="ghost"
        size="sm"
        @click="handleCancel">
        Cancel
      </Button>
      <Button
        class="w-full bg-blue-500 text-white hover:bg-blue-600"
        variant="ghost"
        size="sm"
        @click="handleApplyFilter">
        Apply
      </Button>
    </div>
  </div>
</template>

<style scoped>
  /* width */
  ::-webkit-scrollbar {
    width: 0.25rem;
    height: 0.25rem;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
</style>
