import { FilterField, SingleFilterField, CompositeFilterField, FilterType } from '../types'

export type RansackQuery =
    | {
          [key: string]: string | number | boolean | (string | number)[]
      }
    | {
          m: string
          g: RansackQuery[]
      }

const commonOptions = {
    equals: 'eq',
    notEquals: 'not_eq',
    empty: 'blank',
    notEmpty: 'not_null',
    greaterThan: 'gt',
    greaterThanOrEquals: 'gteq',
    lessThan: 'lt',
    lessThanOrEquals: 'lteq',
    between: 'between'
}

const optionsMap: Record<FilterType, Record<string, string>> = {
    text: {
        ...commonOptions,
        notEmpty: 'present',
        contains: 'cont',
        notContains: 'not_cont',
        startsWith: 'start',
        endsWith: 'end'
    },
    number: {
        ...commonOptions
    },
    date: {
        ...commonOptions,
        before: 'lteq',
        after: 'gteq'
    },
    select: {
        ...commonOptions,
        in: 'in'
    },
    boolean: {
        equals: commonOptions.equals,
        notEquals: commonOptions.notEquals
    },
    time: {
        ...commonOptions,
        before: 'lteq',
        after: 'gteq'
    }
}

function isCompositeFilterField(filterField: FilterField): filterField is CompositeFilterField {
    return 'condition1' in filterField && 'condition2' in filterField && 'operator' in filterField
}

function isSingleFilterField(filterField: FilterField): filterField is SingleFilterField {
    return 'filter' in filterField && 'type' in filterField
}

function isEmptyCondition(condition: SingleFilterField): boolean {
    return condition.type === 'empty' || condition.type === 'notEmpty'
}

function hasValidFilter(condition: SingleFilterField): boolean {
    if (isEmptyCondition(condition)) return true
    return condition.filter != null && condition.filter !== ''
}

function isEmptyFilterValue(filter: any): boolean {
    if (Array.isArray(filter)) {
        return filter.length === 0
    }
    return filter === '' || filter === null || filter === undefined
}

export function createRansackQuery(filterField: FilterField, field: string): RansackQuery | null {
    if (isCompositeFilterField(filterField)) {
        return createCompositeQuery(filterField, field)
    } else if (isSingleFilterField(filterField)) {
        if (!hasValidFilter(filterField)) {
            return null
        }
        return createQueryFromMap(filterField, field)
    }
    return null
}

function createCompositeQuery(filterField: CompositeFilterField, field: string): RansackQuery | null {
    const { isSelectMultipleOptions, isStringify, condition1, condition2, operator } = filterField

    const enhancedCondition1: SingleFilterField = {
        ...condition1,
        isSelectMultipleOptions,
        isStringify
    }

    const enhancedCondition2: SingleFilterField = {
        ...condition2,
        isSelectMultipleOptions,
        isStringify
    }

    if (isEmptyCondition(enhancedCondition1) && isEmptyCondition(enhancedCondition2)) {
        return createEmptyCompositeQuery(enhancedCondition1, enhancedCondition2, field, operator)
    }

    if (isEmptyCondition(enhancedCondition1) || isEmptyCondition(enhancedCondition2)) {
        return createMixedEmptyQuery(enhancedCondition1, enhancedCondition2, field, operator)
    }

    const condition1Query = createQueryFromMap(enhancedCondition1, field)
    const condition2Query = createQueryFromMap(enhancedCondition2, field)

    if (!condition1Query || !condition2Query) return null

    return {
        m: operator?.toLowerCase() || 'or',
        g: [condition1Query, condition2Query]
    }
}

function createEmptyCompositeQuery(
    condition1: SingleFilterField,
    condition2: SingleFilterField,
    field: string,
    operator: 'AND' | 'OR'
): RansackQuery {
    const queries: RansackQuery[] = []

    if (condition1.type === 'empty') {
        if (condition1.isSelectMultipleOptions && condition1.isStringify) {
            queries.push({ [`${field}_blank`]: true })
            queries.push({ [`${field}_eq`]: '[]' })
        } else {
            queries.push({ [`${field}_blank`]: true })
        }
    } else if (condition1.type === 'notEmpty') {
        if (condition1.isStringify) {
            queries.push({ [`${field}_present`]: true })
            queries.push({ [`${field}_not_eq`]: '[]' })
        } else
            queries.push({ [`${field}_not_null`]: true })
    }

    if (condition2.type === 'empty') {
        if (condition2.isSelectMultipleOptions && condition2.isStringify) {
            queries.push({ [`${field}_blank`]: true })
            queries.push({ [`${field}_eq`]: ['[]'] })
        } else {
            queries.push({ [`${field}_blank`]: true })
        }
    } else if (condition2.type === 'notEmpty') {
        if (condition2.isStringify) {
            queries.push({ [`${field}_present`]: true })
            queries.push({ [`${field}_not_eq`]: '[]' })
        }
        else
            queries.push({ [`${field}_not_null`]: true })
    }

    return {
        m: operator.toLowerCase(),
        g: queries
    }
}

function createMixedEmptyQuery(
    condition1: SingleFilterField,
    condition2: SingleFilterField,
    field: string,
    operator: 'AND' | 'OR'
): RansackQuery | null {
    if (
        (condition1.type === 'empty' && isEmptyFilterValue(condition2.filter)) ||
        (condition2.type === 'empty' && isEmptyFilterValue(condition1.filter))
    ) {
        if (condition1.isSelectMultipleOptions && condition1.isStringify) {
            return {
                m: 'or',
                g: [{ [`${field}_blank`]: true }, { [`${field}_eq`]: '[]' }]
            }
        }
        return {
            [`${field}_blank`]: true
        }
    }

    if (condition1.type === 'notEmpty' && isEmptyFilterValue(condition2.filter)) {
        if (condition1.isStringify) {
            let query: RansackQuery = { [`${field}_present`]: true };
            if (condition1.isSelectMultipleOptions) {
                query[`${field}_not_eq`] = '[]'
            }
            return query
        }
        const currentOptionsMap = optionsMap[condition1.filterType]
        const keySuffix = currentOptionsMap[condition1.type || '']
        return {
            [`${field}_${keySuffix}`]: true
        }
    }

    const emptyCondition = isEmptyCondition(condition1) ? condition1 : condition2
    const valueCondition = isEmptyCondition(condition1) ? condition2 : condition1

    const emptyQuery = createQueryFromMap(emptyCondition, field)
    const valueQuery = createQueryFromMap(valueCondition, field)

    if (!emptyQuery || !valueQuery) return null

    if (emptyCondition.type === 'empty' && emptyCondition.isStringify && emptyCondition.isSelectMultipleOptions) {
        return {
            m: operator.toLowerCase(),
            g: [valueQuery, { [`${field}_blank`]: true }, { [`${field}_eq`]: '[]' }]
        }
    }
    if (emptyCondition.type === 'empty' && emptyCondition.isStringify) {
        return {
            m: operator.toLowerCase(),
            g: [valueQuery, { [`${field}_blank`]: true }]
        }
    }

    return {
        m: operator.toLowerCase(),
        g: [emptyQuery, valueQuery]
    }
}

function createQueryFromMap(filterField: SingleFilterField, field: string): RansackQuery | null {
    const currentOptionsMap = optionsMap[filterField.filterType]
    const keySuffix = currentOptionsMap[filterField.type || '']

    if (!keySuffix) {
        return null
    }

    if (filterField.type === 'empty' && filterField.isStringify && filterField.isSelectMultipleOptions) {
        return {
            m: 'or',
            g: [{ value_blank: true }, { value_eq: '[]' }]
        }
    }

    if (filterField.type === 'empty' || filterField.type === 'notEmpty') {
        let query: RansackQuery = { [`${field}_${keySuffix}`]: true }

        // if (filterField.type === 'empty' && filterField.isStringify) {
        //     return {
        //         m: 'or',
        //         g: [query, { [`${field}_eq`]: '[]' }]
        //     }
        // }
        if (filterField.type === 'notEmpty' && filterField.isStringify) {
            query = { [`${field}_present`]: true }
        }

        return query
    }

    if (filterField.isSelectMultipleOptions && Array.isArray(filterField.filter) && filterField.isStringify) {
        if (filterField.filter.length > 0) {
            const optionsClause = filterField.filter.flatMap(value => [
                `[${value}]`,
                `[%,${value},%]`,
                `[${value},%]`,
                `[%,${value}]`
            ])
            return { [`${field}_matches_any`]: optionsClause }
        } else {
            return { [`${field}_eq`]: '[]' }
        }
    }

    if (filterField.type === 'between' && Array.isArray(filterField.filter) && filterField.filter.length === 2) {
        return {
            m: 'and',
            g: [
                {
                    [`${field}_${commonOptions.greaterThanOrEquals}`]: filterField.filter[0]
                },
                {
                    [`${field}_${commonOptions.lessThanOrEquals}`]: filterField.filter[1]
                }
            ]
        }
    }

    if (filterField.type === 'in' && Array.isArray(filterField.filter) && filterField.filter.length > 0) {
        return { [`${field}_${keySuffix}`]: filterField.filter }
    }

    if (filterField.type === 'in' && Array.isArray(filterField.filter) && filterField.filter.length === 0) {
        return null
    }

    if (isEmptyFilterValue(filterField.filter)) {
        return null
    }

    return {
        [`${field}_${keySuffix}`]: filterField.filter as string | number | boolean | (string | number)[]
    }
}

export { optionsMap, commonOptions }
